import { FC } from "react";
import { theme } from "tailwind.config";

interface Props {
  liked: boolean;
}

const LikeIcon: FC<Props> = ({ liked }) => {
  return liked ? (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className="drop-shadow-icon"
    >
      <path
        d="M8.00004 14.2333L7.03337 13.3533C3.60004 10.24 1.33337 8.18667 1.33337 5.66667C1.33337 3.61333 2.94671 2 5.00004 2C6.16004 2 7.27337 2.54 8.00004 3.39333C8.72671 2.54 9.84004 2 11 2C13.0534 2 14.6667 3.61333 14.6667 5.66667C14.6667 8.18667 12.4 10.24 8.96671 13.36L8.00004 14.2333Z"
        fill={theme.colors.like}
      />
    </svg>
  ) : (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="drop-shadow-icon"
    >
      <path
        d="M16.5 3C14.76 3 13.09 3.81 12 5.09C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.42 2 8.5C2 12.28 5.4 15.36 10.55 20.04L12 21.35L13.45 20.03C18.6 15.36 22 12.28 22 8.5C22 5.42 19.58 3 16.5 3ZM12.1 18.55L12 18.65L11.9 18.55C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5C9.04 5 10.54 5.99 11.07 7.36H12.94C13.46 5.99 14.96 5 16.5 5C18.5 5 20 6.5 20 8.5C20 11.39 16.86 14.24 12.1 18.55Z"
        fill={theme.colors.white}
      />
    </svg>
  );
};
export default LikeIcon;
