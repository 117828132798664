import { FC } from "react";

interface Props {
  kind: "new";
}

const LabelBadge: FC<Props> = ({ kind }) => {
  return (
    <div className="text-white bg-black rounded-full w-[32px] h-[32px] uppercase grid place-items-center place-content-center text-tiny">
      {kind}
    </div>
  );
};
export default LabelBadge;
