import { FC, useMemo } from "react";
import Link from "next/link";
import {
  ListItemKind,
  ProductKind,
  ProductVariationWithProductResponse,
} from "@/web-client/api";
import { LikeMutateHandler } from "@/features/like/hooks/useLike";
import useLikeToProduct from "@/features/like/hooks/useLikeToProduct";
import useVariation from "@/features/product_variation/hooks/useVariation";
import useProductVariation from "@/features/product_variation/hooks/useProductVariation";
import clsx from "clsx";
import Image from "next/image";
import Maybe from "@/components/Maybe";
import LinkAsModal, { ModalEntityType } from "@/components/LinkAsModal";
import ProductVariationPrice from "@/features/product_variation/components/ProductVariationPrice";
import useDisclosure from "@/hooks/useDisclosure";
import CartBtnWrapper from "@/components/CartBtnWrapper";
import { useSignInRequiredAction } from "@/features/user_authentication";
import { RedirectReason } from "@/domain/values/RedirectReason";
import IconButton from "@/components/IconButton";
import LikeIcon from "@/features/like/components/LikeIcon";
import dynamic from "next/dynamic";
import RenderIfAuth from "@/components/RenderIfAuth";
import IconMapsHomeWork from "@/assets/imgs/svg/icon-maps_home_work.svg";
import IconAddCart from "@/assets/imgs/svg/icon_add_cart.svg";
import IconBookmark from "@/assets/imgs/svg/icon-bookmark.svg";
import LabelBadge from "@/components/LabelBadge";
import { useIsNewProductVariation } from "@/features/product_variation/hooks/useIsNewProductVariation";
import { getReasonText } from "@/utils/getReasonText";

const DynamicModalAddList = dynamic(
  () => import("@/features/list/components/ModalAddList"),
);

const DynamicModalPinnedPosts = dynamic(
  () => import("@/features/post/components/ModalPinnedPosts"),
);

type Props = {
  productVariation: ProductVariationWithProductResponse;
  clickHandler?(productVariation: ProductVariationWithProductResponse): void;
  mutate?: LikeMutateHandler<ProductVariationWithProductResponse>;
  displaySize?: boolean;
  board_id?: number;
};

const ProductVariationItem: FC<Props> = ({
  productVariation,
  clickHandler,
  mutate,
  displaySize,
  board_id,
}) => {
  // 紐づいた事例写真モーダルを開閉するためのステート
  const {
    isOpen: openedModalPinndedPosts,
    openHandler: openModalPinnedPosts,
    closeHandler: closeModalPinnedPosts,
  } = useDisclosure();

  // リスト追加モーダルを開閉するためのステート
  const { isOpen, openHandler, closeHandler } = useDisclosure();

  const { isSampleAvailable, isIndirectAvailable } =
    useProductVariation(productVariation);

  const { likeHandler } = useLikeToProduct<ProductVariationWithProductResponse>(
    productVariation,
    productVariation.product.kind,
    mutate,
    productVariation.product.maker_id,
  );

  const { displaySize: size, hasSizeInfo } = useVariation(productVariation);

  const showPrice = useMemo(
    () =>
      // メーカーと契約済みでかつ販売中の場合
      productVariation.product.maker.is_contracted &&
      !productVariation.is_discontinued,
    [productVariation],
  );

  const {
    executeAction: executeActionForLike,
    SignInModalComponent: SignInModalComponentForLike,
  } = useSignInRequiredAction({
    message: getReasonText(RedirectReason.Like),
    action: likeHandler,
  });

  const {
    executeAction: executeActionForAddList,
    SignInModalComponent: SignInModalComponentForAddList,
  } = useSignInRequiredAction({
    message: getReasonText(RedirectReason.AddList),
    action: openHandler,
  });

  const isNewProductVariation = useIsNewProductVariation(
    productVariation.created_at,
  );

  const showBtn = useMemo(
    () => productVariation.posts_count > 0 || isSampleAvailable,
    [productVariation.posts_count, isSampleAvailable],
  );

  return (
    <>
      <article
        className={clsx(
          "relative flex flex-col gap-8 group",
          "group/product-variation-item",
        )}
      >
        <div className={clsx("relative aspect-square")}>
          <LinkAsModal
            entityId={productVariation.product_id}
            entityType={ModalEntityType.Product}
            as={`/products/${productVariation.product.id}`}
            params={{ v_id: productVariation.id.toString() }}
            className="absolute inset-0 w-full h-full"
          >
            <a onClick={() => clickHandler && clickHandler(productVariation)}>
              <Image
                className={clsx(
                  "object-contain object-center bg-white",
                  "group-hover/product-variation-item:brightness-90 transition-all",
                  "border border-primary rounded-xs overflow-hidden",
                )}
                alt={productVariation.full_name}
                src={productVariation.upload_image.urls.small}
                unoptimized
                fill
              />
            </a>
          </LinkAsModal>

          <Maybe condition={isIndirectAvailable}>
            <p className="absolute bottom-1 left-1 bg-black p-4 text-white rounded-xxs text-tiny leading-none">
              最短当日発送
            </p>
          </Maybe>

          <div className="absolute bottom-[-4px] right-[-4px]">
            <IconButton
              variant="ghost"
              aria-label={`LIKEを${productVariation.has_liked ? "はずす" : "つける"}`}
              onClick={executeActionForLike}
              size="large"
            >
              <LikeIcon liked={productVariation.has_liked} />
            </IconButton>
            {SignInModalComponentForLike}
          </div>

          <div className="absolute top-[-4px] right-[-4px]">
            <IconButton
              variant="ghost"
              onClick={executeActionForAddList}
              aria-label="リストに追加"
              size="large"
            >
              <IconBookmark className="drop-shadow-icon" />
            </IconButton>

            <RenderIfAuth>
              <DynamicModalAddList
                isOpen={isOpen}
                closeHandler={() => closeHandler()}
                selectedItem={productVariation}
                kind={
                  productVariation.product.kind ===
                  ProductKind.BUILDING_MATERIAL
                    ? ListItemKind.BUILDING_MATERIAL
                    : ListItemKind.INTERIOR
                }
              />
            </RenderIfAuth>
            {SignInModalComponentForAddList}
          </div>
        </div>

        <div className="grid gap-4">
          <p className="text-xs text-gray-600 leading-none">
            <Link href={`/makers/${productVariation.product.maker.id}`}>
              {productVariation.product.maker.name}
            </Link>
          </p>

          <h2 className="text-xs font-bold leading-normal min-h-[2lh] line-clamp-2">
            <LinkAsModal
              entityId={productVariation.product_id}
              entityType={ModalEntityType.Product}
              as={`/products/${productVariation.product.id}`}
              params={{ v_id: productVariation.id.toString() }}
            >
              <a onClick={() => clickHandler && clickHandler(productVariation)}>
                {productVariation.full_name}
              </a>
            </LinkAsModal>
          </h2>

          <div className="text-xs leading-none">
            <div className="line-clamp-1 min-h-[1lh]">
              {showPrice && (
                <ProductVariationPrice productVariation={productVariation} />
              )}
            </div>

            {displaySize && (
              <div className="line-clamp-1 min-h-[1lh]">
                {hasSizeInfo && <div>{size}(mm)</div>}
              </div>
            )}
          </div>
        </div>

        {showBtn && (
          <div className="grid grid-flow-col grid-cols-2 border border-primary rounded-xxs overflow-hidden">
            <Maybe condition={productVariation.posts_count > 0}>
              <button
                type="button"
                className={clsx(
                  "p-8 bg-gray-50 only:col-span-full text-tiny leading-none",
                  "inline-grid grid-cols-[1fr_auto_1fr] items-center gap-8",
                )}
                onClick={openModalPinnedPosts}
                aria-label={`${productVariation.full_name}の利用事例を見る`}
              >
                <span className="col-span-1">
                  <IconMapsHomeWork
                    width={16}
                    height={16}
                    className="fill-black"
                  />
                </span>
                <span className="col-span-1">
                  {productVariation.posts_count}件
                </span>
              </button>
            </Maybe>

            <Maybe condition={isSampleAvailable}>
              <CartBtnWrapper
                productVariation={productVariation}
                product={productVariation.product}
                board_id={board_id}
                btnClassName="only:col-span-full grid"
                btnView={
                  <span
                    className={clsx(
                      "bg-black text-white p-8 text-tiny",
                      "flex items-center justify-center gap-4",
                    )}
                  >
                    <IconAddCart
                      width={16}
                      height={16}
                      className="fill-white"
                    />
                    <span>CART</span>
                  </span>
                }
                unavailableView={
                  <span
                    className={clsx(
                      "bg-black text-white p-8 text-tiny",
                      "flex items-center justify-center gap-4",
                      "opacity-25",
                    )}
                  >
                    <IconAddCart
                      width={16}
                      height={16}
                      className="fill-white"
                    />

                    <span>CART</span>
                  </span>
                }
              />
            </Maybe>
          </div>
        )}

        {isNewProductVariation && (
          <div className="absolute top-[4px] left-[4px] pointer-events-none">
            <LabelBadge kind="new" />
          </div>
        )}
      </article>

      {openedModalPinndedPosts && (
        <DynamicModalPinnedPosts
          productVariation={productVariation}
          isOpen={openedModalPinndedPosts}
          closeHandler={closeModalPinnedPosts}
        />
      )}
    </>
  );
};

export default ProductVariationItem;
