import { useMemo } from "react";
import { parseISO, differenceInDays } from "date-fns";

export const useIsNewProductVariation = (created_at: string): boolean => {
  // created_at が現在時刻から2週間以内の場合は true を返す
  const isNew = useMemo(() => {
    const now = new Date();
    const createdAt = parseISO(created_at);
    const diffDays = differenceInDays(now, createdAt);

    return diffDays <= 14; // 2週間以内
  }, [created_at]);

  return isNew;
};
